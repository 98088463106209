import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

export type CardProps = HTMLAttributes<HTMLDivElement>;

const Card: React.FC<CardProps> = ({ children, ...props }) => {
	return (
		<div {...props} className={clsx('rounded p-2 bg-secondary-500', props.className)}>
			{children}
		</div>
	);
};

export default Card;
