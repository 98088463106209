import Banner from '@components/Banner';
import Card from '@components/Card';
import Page from '@components/Layout/Page';
import Link from '@components/Link';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

const ValueItem: React.FC<{ title: string }> = ({ title, children }) => (
	<div>
		<h2 className="text-2xl font-bold">{title}</h2>
		<p className="px-3 sm:px-4 mt-2">{children}</p>
	</div>
);

const AboutUsPage = () => {
	const data = useStaticQuery(graphql`
		{
			file(relativePath: { eq: "small-team.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1300) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);
	return (
		<Page
			title="About Us"
			banner={
				<Banner image={{ fluid: data.file.childImageSharp.fluid }}>
					<h1 className="text-5xl mb-3 font-bold">About Us</h1>
					<div className="max-w-2xl mx-auto">
						<p>
							Based in Boulder, Colorado at the University of Colorado Boulder, HackCU brings
							together students of various backgrounds and experience levels at our events, which
							primarily comprise workshops and hackathons. We&apos;re a team of like minded students
							with the same mission in mind: bringing people together to create a safe space for
							students to be inventive and creative! We&apos;re completely student run, and work
							extensively with our university and corporate entities to bring you the best content
							and events. Want to learn more? Let us know at{' '}
							<Link className="text-primary-500" outside to="mailto:contact@hackcu.org">
								contact@hackcu.org
							</Link>
							.
						</p>
					</div>
				</Banner>
			}
		>
			<div className="flex flex-col text-center pt-6 space-y-5">
				<Card>
					<h1 className="text-6xl mb-2 font-bold">Our Mission</h1>
					<p>Bring people together to create, learn, and have fun!</p>
				</Card>

				<Card>
					<h1 className="text-6xl mb-2 font-bold">Our Story</h1>
					<p className="px-3 ms:px-4">
						HackCU began in 2014 when a group of passionate students wanted to provide other
						students the time and space to express their creativity and work on things they usually
						don&apos;t get a chance to. Since then we&apos;ve been committed to fulfilling our
						mission of bringing students high-quality, inclusive events open to everyone
						irrespective of their experience.
					</p>
				</Card>

				<Card>
					<h1 className="text-6xl mb-2 font-bold">Our Values</h1>

					<div className="space-y-2">
						<ValueItem title="Hacker First">
							Everything we do is aimed at having hackers enjoy their time at our events.
						</ValueItem>

						<ValueItem title="Self-Improvement">
							At all times, HackCU encourages hackers and team members to improve, to learn, and to
							push themselves beyond their limits.
						</ValueItem>

						<ValueItem title="Open Source">
							HackCU will be open to share its knowledge to anyone interested and to teach other
							students what we learn. This means all of our projects will be open-sourced.
						</ValueItem>

						<ValueItem title="Student Run and Independent">
							HackCU is and always will be an independent entity run by students at CU Boulder. We
							won&apos;t be sellouts.
						</ValueItem>

						<ValueItem title="Anyone is HackCU">
							We welcome everyone regardless of their background, gender, race, major, or level of
							experience.
						</ValueItem>

						<ValueItem title="Inspire">
							Inspire people, to reach for more. Anyone can change the world around themselves!
						</ValueItem>
					</div>
				</Card>
			</div>
		</Page>
	);
};

export default AboutUsPage;
